import React from "react";
import Banner from "./Banner";
import Profile from "./Profile";

const Leadership = () => {
  return (
    <div>
      <Banner />
      <Profile />
    </div>
  );
};

export default Leadership;
